import React from 'react';
import Banner from './components/Banner';
import PostFeed from './components/PostFeed';
import './App.css';

function App() {
  return (
    <div className="App">
      <Banner />
      <PostFeed />
    </div>
  );
}

export default App;
