import React, { useState, useRef, useEffect } from 'react';
import { IoFilter } from 'react-icons/io5'; // Import IoFilter icon
import './FilterBar.css';

const FilterBar = ({ selectedTopics, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedPillIndex, setSelectedPillIndex] = useState(null); // Track selected pill
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus(); // Focus on the input when the component mounts
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault(); // Prevent default action
      addTopic(); // Add the topic when comma or Enter is pressed
    } else if (e.key === 'Backspace' || e.key === 'Delete') {
      if (inputValue === '' && selectedPillIndex !== null) {
        removeTopic(selectedPillIndex);
      } else if (inputValue === '' && selectedPillIndex === null) {
        removeLastTopic();
      }
    }
  };

  const addTopic = () => {
    const newTopic = inputValue.trim();
    if (newTopic && !selectedTopics.includes(newTopic)) {
      onChange([...selectedTopics, newTopic.toLowerCase()]);
    }
    setInputValue('');
  };

  const removeLastTopic = () => {
    const newTopics = selectedTopics.slice(0, selectedTopics.length - 1);
    onChange(newTopics);
  };

  const removeTopic = (index) => {
    const newTopics = selectedTopics.filter((_, i) => i !== index);
    onChange(newTopics);
    setSelectedPillIndex(null); // Reset selected pill after deletion
  };

  const handlePillClick = (index) => {
    setSelectedPillIndex(index); // Select the pill
    inputRef.current.focus(); // Bring up the keyboard on mobile
  };

  return (
    <div className="filter-bar">
      <div className="pill-container">
        <IoFilter className="filter-icon" aria-label="Filter" />

        {selectedTopics.map((topic, index) => (
          <div
            key={topic}
            className={`topic-pill ${index === selectedPillIndex ? 'selected' : ''}`} // Add selected class if this pill is selected
            tabIndex="0" // Makes the pill focusable
            onClick={() => handlePillClick(index)} // Handle click to select the pill
          >
            {topic}
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={!inputValue ? "Filter by topic..." : ""}
          ref={inputRef}
          className="filter-input"
        />
      </div>
    </div>
  );
};

export default FilterBar;
