import React, { useEffect, useState, useCallback } from 'react';
import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Post from './Post';
import FilterBar from './FilterBar';
import './PostFeed.css';

const NUM_QUERY_LIMIT = 50;

const PostFeed = () => {
  const [posts, setPosts] = useState([]); // Store all fetched posts
  const [lastVisible, setLastVisible] = useState(null); // Track the last visible document for pagination
  const [loading, setLoading] = useState(false); // Show loading spinner/message
  const [loadingMore, setLoadingMore] = useState(false); // Track loading state for 'Load More' button
  const [noMorePosts, setNoMorePosts] = useState(false); // Track if there are no more posts to load
  const [topics, setTopics] = useState([]); // Store all topics
  const [selectedTopics, setSelectedTopics] = useState([]); // Track selected topics from filter

  // Fetch available topics for filtering
  const fetchTopics = async () => {
    try {
      const postsQuery = query(collection(db, 'processed-posts'));
      const postDocs = await getDocs(postsQuery);

      const topicsSet = new Set();
      postDocs.forEach(doc => {
        const postData = doc.data();
        if (postData.topics) {
          postData.topics.forEach(topic => topicsSet.add(topic));
        }
      });

      setTopics([...topicsSet]);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  // Fetch posts based on selected topics (or all posts if no topic is selected)
  const fetchPosts = useCallback(async () => {
    setLoading(true);
    try {
      const baseQuery = collection(db, 'processed-posts');
      let postsQuery;

      if (selectedTopics.length > 0) {
        postsQuery = query(
          baseQuery,
          where('is_interesting', '==', true),
          where('is_advertisement', '==', false),
          where('topics', 'array-contains-any', selectedTopics),
          orderBy('creation_time', 'desc'),
          limit(NUM_QUERY_LIMIT)
        );
      } else {
        postsQuery = query(
          baseQuery,
          where('is_general', '==', true),
          where('is_interesting', '==', true),
          where('is_advertisement', '==', false),
          orderBy('creation_time', 'desc'),
          limit(NUM_QUERY_LIMIT)
        );
      }

      const postDocs = await getDocs(postsQuery);
      const fetchedPosts = postDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      setPosts(fetchedPosts); // Reset posts when fetching new data (like when changing filter)
      setLastVisible(postDocs.docs[postDocs.docs.length - 1]); // Track the last document
      setNoMorePosts(false); // Reset "no more posts" flag when fetching new data
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedTopics]);

  // Load more posts when the user scrolls to the bottom 20%
  const loadMorePosts = useCallback(async () => {
  if (!lastVisible || loadingMore || noMorePosts) return; // Prevent further requests while loading

  setLoadingMore(true);

  try {
    const baseQuery = collection(db, 'processed-posts');
    let postsQuery;

    if (selectedTopics.length > 0) {
      postsQuery = query(
        baseQuery,
        where('is_interesting', '==', true),
        where('is_advertisement', '==', false),
        where('topics', 'array-contains-any', selectedTopics),
        orderBy('creation_time', 'desc'),
        startAfter(lastVisible),
        limit(NUM_QUERY_LIMIT)
      );
    } else {
      postsQuery = query(
        baseQuery,
        where('is_general', '==', true),
        where('is_interesting', '==', true),
        where('is_advertisement', '==', false),
        orderBy('creation_time', 'desc'),
        startAfter(lastVisible),
        limit(NUM_QUERY_LIMIT)
      );
    }

    const postDocs = await getDocs(postsQuery);
    const newPosts = postDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Filter out duplicates by checking if any of the new posts already exist in the posts state
    const filteredNewPosts = newPosts.filter(newPost => !posts.some(post => post.id === newPost.id));

    if (filteredNewPosts.length > 0) {
      setPosts(prevPosts => [...prevPosts, ...filteredNewPosts]); // Append only unique new posts
      setLastVisible(postDocs.docs[postDocs.docs.length - 1]); // Update the last visible document
    } else {
      setNoMorePosts(true); // No more posts to load
    }
  } catch (error) {
    console.error('Error loading more posts:', error);
  } finally {
    setLoadingMore(false);
  }
}, [lastVisible, loadingMore, selectedTopics, noMorePosts, posts]);


  // Detect when the user has scrolled to the bottom 20%
  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const scrollPosition = scrollTop + clientHeight;
    const threshold = scrollHeight * 0.9; // Trigger loading when 90% scrolled

    if (scrollPosition >= threshold && !loadingMore && !loading && !noMorePosts) {
      loadMorePosts();
    }
  }, [loadMorePosts, loadingMore, loading, noMorePosts]);

  // Set up scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchTopics(); // Fetch available topics on initial load
    fetchPosts(); // Fetch posts based on topics or all
  }, [selectedTopics, fetchPosts]);

  return (
    <div className="post-feed">
      <FilterBar topics={topics} selectedTopics={selectedTopics} onChange={setSelectedTopics} />
      {posts.length === 0 && !loading && <p>No posts available.</p>}
      {posts.map(post => (
        <Post
          key={post.id}
          accountName={post.account_name}
          summary={post.summary}
          creationTime={post.creation_time}
          sourceUrl={post.url}
        />
      ))}
      {loading && <p>Loading...</p>}

      {/* Loading indicator when fetching more posts */}
      {loadingMore && <p>Loading more posts...</p>}

      {/* Message when there are no more posts */}
      {noMorePosts && <p>That's All Folks!</p>}
    </div>
  );
};

export default PostFeed;
