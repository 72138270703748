import React from 'react';
import './Post.css';
import ReactMarkdown from 'react-markdown';

function formatDate(dateString) {
  // Parse the dateString as UTC
  const date = new Date(dateString); // date is in UTC, but displayed in local time
  const today = new Date();

  // Extract hours, minutes, and AM/PM in local time
  let hours = date.getHours(); // Get local hours
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Get local minutes
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  const isToday =
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate();

  if (isToday) {
    return formattedTime;
  } else {
    const day = date.getDate().toString().padStart(2, '0'); // Get local day
    const month = date.toLocaleString('en-US', { month: 'long' }); // Get local month
    const year = date.getFullYear(); // Get local year

    return `${day} ${month} ${year}, ${formattedTime}`;
  }
}

function isUrlValid(string) {
  if (string.startsWith('cnn.it')) {
    return true;
  }
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

function decorateHyperLinks(summary) {
  const tokens = summary.split(" ");
  const possible_url = tokens.pop();
  const last_word = tokens.pop();

  if (isUrlValid(possible_url)) {
    let hyperlink_word = last_word;
    if (last_word.endsWith(':')) {
      hyperlink_word = last_word.slice(0, -1);
    }
    const htmlString = `<a href="${possible_url}" class="post-links"> ${hyperlink_word} </a>`;
    return [tokens.join(" ") + " " + htmlString, true];
  } else {
    return [summary, false];
  }
}

function renderSummary(summary){
  const cleanedSummary = decorateHyperLinks(summary);
  const text = cleanedSummary[0];
  if (cleanedSummary[1]) {
    return <div dangerouslySetInnerHTML={{ __html:text}}/> 
  } else {
    return <ReactMarkdown>{text}</ReactMarkdown>
  }
}

const Post = ({ accountName, summary, creationTime, sourceUrl}) => {
  
  return (
    <div className="post">
      <div className="post-header">
        <span className="account-name">{accountName}</span>
        <span className="creation-time">
          {formatDate(creationTime)}
        </span>
      </div>
      <div className="post-summary">{renderSummary(summary)}</div>
      {isUrlValid(sourceUrl) && (
        <div className="post-source">
          <a href={sourceUrl} className="source-link" target="_blank" rel="noopener noreferrer">
            Source
          </a>
        </div>
      )}
    </div>
  );
};


export default Post;
